import React from "react";
import './IntervalSettings.css';
import {Input, Message} from 'semantic-ui-react';

class IntervalSettings extends React.Component {
    constructor(props) {
        super(props);

        this.emitChange = this.emitChange.bind(this);
    }

    emitChange(type, value) {
        const change = {};

        change[type] = value;

        this.props.onChangeSettings(change);
    }

    render() {
        return (
            <div className="IntervalSettings">
                <h3 className="IntervalSettings__title">
                    Interval Settings
                </h3>
                <div className="IntervalSettings__row">
                    <label for="up-number-input" className="IntervalSettings__label">Up:</label>
                    <Input
                        className="IntervalSettings__input"
                        id="up-number-input"
                        min="1"
                        label="s"
                        labelPosition='right'
                        value={this.props.up}
                        type="number"
                        onChange={e => this.emitChange('timeUp', e.target.value)}/>
                </div>
                <div className="IntervalSettings__row">
                    <label htmlFor="down-number-input" className="IntervalSettings__label">Down:</label>
                    <Input
                        className="IntervalSettings__input"
                        id="down-number-input"
                        min="1"
                        label="s"
                        labelPosition='right'
                        value={this.props.down}
                        type="number"
                        onChange={e => this.emitChange('timeDown', e.target.value)}/>
                </div>
            </div>
        );
    }
}

export default IntervalSettings;

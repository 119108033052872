export default {
    paths: [
        {
            endPath: 'M49.548,157.817c0,0-41.29-32.016-41.29-66.065c0-33.032,16.516-82.581,74.323-74.323c41.685,5.955,49.548,82.581,49.548,82.581L49.548,157.817z',
            startPath: 'M49.5,163.6c0,0-41.3-27.4-41.3-56.6c0-28.3,16.5-70.8,74.3-63.7c41.7,5.1,49.5,70.8,49.5,70.8L49.5,163.6z',
            style: {
                fill: '#6B676E'
            }
        },
        {
            endPath: 'M462.453,157.817c0,0,41.29-32.016,41.29-66.065c0-33.032-16.516-82.581-74.323-74.323\n' +
                '\t\tc-41.685,5.955-49.548,82.581-49.548,82.581L462.453,157.817z',
            startPath: 'M462.5,163.6c0,0,41.3-27.4,41.3-56.6c0-28.3-16.5-70.8-74.3-63.7c-41.7,5.1-49.5,70.8-49.5,70.8L462.5,163.6z\n' +
                '\t\t',
            style: {
                fill: '#6B676E'
            }
        },
        {
            endPath: 'M512,280.774c0,132.263-114.615,214.71-256.001,214.71S0,413.038,0,280.774\n' +
                '\tS141.98,33.032,256.001,33.032S512,148.511,512,280.774z',
            startPath: 'M512,269c0,113.4-114.6,184-256,184S0,382.3,0,269S142,56.7,256,56.7S512,155.6,512,269z',
            style: {
                fill: '#FFEBD2'
            }
        },
        {
            endPath: 'M165.162,421.063c0,41.048,40.67,74.323,90.839,74.323s90.839-33.275,90.839-74.323H165.162z',
            startPath: 'M165.2,389.2c0,35.2,40.7,63.7,90.8,63.7s90.8-28.5,90.8-63.7H165.2z',
            style: {
                fill: '#E9D7C3'
            }
        },
        {
            endPath: 'M322.065,305.549C297.291,264.258,280.775,256,256.001,256c-24.774,0-41.29,8.258-66.065,49.548\n' +
                '\tc-21.244,35.407-39.365,105.632-24.774,123.871c33.032,41.29,82.581,16.516,90.839,16.516c8.258,0,57.807,24.774,90.839-16.516\n' +
                '\tC361.43,411.181,343.308,340.954,322.065,305.549z',
            startPath: 'M322.1,290.2c-24.8-35.4-41.3-42.5-66.1-42.5s-41.3,7.1-66.1,42.5c-21.2,30.3-39.4,90.5-24.8,106.2c33,35.4,82.6,14.2,90.8,14.2s57.8,21.2,90.8-14.2C361.4,380.8,343.3,320.6,322.1,290.2z',
            style: {
                fill: '#FFFFFF'
            }
        },
        {
            endPath: 'M305.549,247.742c0-22.804,18.487-41.29,41.29-41.29c24.774,0,99.097,49.549,99.097,82.581\n' +
                '\t\ts-39.967,72.452-74.323,49.548C346.839,322.065,305.549,264.258,305.549,247.742z',
            startPath: 'M305.5,240.7c0-19.5,18.5-35.4,41.3-35.4c24.8,0,99.1,42.5,99.1,70.8c0,28.3-40,62.1-74.3,42.5\n' +
                '\t\tC346.8,304.4,305.5,254.8,305.5,240.7z',
            style: {
                fill: '#6B676E'
            }
        },
        {
            endPath: 'M206.452,247.742c0-22.804-18.487-41.29-41.29-41.29c-24.774,0-99.097,49.549-99.097,82.581\n' +
                '\t\ts39.967,72.452,74.323,49.548C165.162,322.065,206.452,264.258,206.452,247.742z',
            startPath: 'M206.5,240.7c0-19.5-18.5-35.4-41.3-35.4c-24.8,0-99.1,42.5-99.1,70.8c0,28.3,40,62.1,74.3,42.5\n' +
                '\t\tC165.2,304.4,206.5,254.8,206.5,240.7z',
            style: {
                fill: '#6B676E'
            }
        },
        {
            endPath: 'M256.001,363.311c-15.03,0-57.807,2.834-57.807,16.516c0,24.819,52.742,42.62,57.807,41.29\n' +
                '\t\tc5.064,1.33,57.807-16.472,57.807-41.29C313.807,366.144,271.03,363.311,256.001,363.311z',
            startPath: 'M256,339.7c-15,0-57.8,2.4-57.8,14.2c0,21.3,52.7,36.5,57.8,35.4c5.1,1.1,57.8-14.1,57.8-35.4\n' +
                '\t\tC313.8,342.2,271,339.7,256,339.7z',
            style: {
                fill: '#464655'
            }
        },
        {
            endPath: 'M148.64600000000002,247.742a16.516,16.516 0 1,0 33.032,0a16.516,16.516 0 1,0 -33.032,0',
            startPath: 'M148.7,240.7a16.5,14.2 0 1,0 33,0a16.5,14.2 0 1,0 -33,0',
            style: {
                fill: '#464655'
            }
        },
        {
            endPath: 'M330.323,247.742a16.516,16.516 0 1,0 33.032,0a16.516,16.516 0 1,0 -33.032,0',
            startPath: 'M330.3,240.7a16.5,14.2 0 1,0 33,0a16.5,14.2 0 1,0 -33,0',
            style: {
                fill: '#464655'
            }
        },
    ]
};
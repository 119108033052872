import React from 'react';
import './App.css';
import TWEEN from '@tweenjs/tween.js';
import { interpolate } from 'flubber';
import character from "./character";
import Buddy from "./components/Buddy";
import ProgressBar from "./components/ProgressBar";
import IntervalSettings from "./components/IntervalSettings";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.paths = character
          .paths
          .map(({ startPath, endPath, style }) => {
            return {
              interpolator: interpolate(startPath, endPath),
              style
            };
          });
    this.state = {
      timeUp: 6,
      timeDown: 6,
      character,
      direction: 'UP',
      percent: 10
    };
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }
  handleTimeChange(change) {
    console.log(change);
    this.setState(change);
  }
  componentDidMount() {
    const fps = 60;
    const interval = 1000/fps;

    setInterval(() => {
      const currentPercent = this.state.percent;
      let newPercent = 0;

      if (this.state.direction === 'UP') {
        newPercent = currentPercent + (100 / (this.state.timeUp * fps));
        newPercent = Math.min(100, newPercent);

        if (newPercent === 100) {
          this.setState({ direction: 'DOWN' });
        }
      } else {
        newPercent = currentPercent - (100 / (this.state.timeDown * fps));
        newPercent = Math.max(0, newPercent);

        if (newPercent === 0) {
          this.setState({ direction: 'UP' });
        }
      }



      this.setState({ percent: newPercent });
    }, interval);
  }
  render() {
    const adjustedPercentage = TWEEN.Easing.Cubic.InOut(this.state.percent/100);

    return (
      <div className="App clearfix">
        <div className="App-container">
          <div className="App-container-row">
            <h1 className="App-title">Breathe Buddies</h1>
          </div>
          <div className="App-container-row">
            <div className="App-chart-container">
              <ProgressBar percent={adjustedPercentage}/>
            </div>
            <div className="App-character-container">
              <div className="App-character">
                <Buddy character={this.state.character} percent={adjustedPercentage}/>
              </div>
            </div>
          </div>
          <div>
            <IntervalSettings
                onChangeSettings={this.handleTimeChange}
                up={this.state.timeUp}
                down={this.state.timeDown}/>
          </div>
        </div>
      </div>
    );
  }
}

export default App;

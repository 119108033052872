import React from "react";
import './ProgressBar.css';

class ProgressBar extends React.Component {
    render() {
        const height = this.props.percent * 100 + '%';

        return (
            <div className="ProgressBar">
                <div className="ProgressBar__progress" style={{ height }}/>
            </div>
        );
    }
}

export default ProgressBar;

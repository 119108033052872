import React from "react";
import {interpolate} from "flubber";

class Buddy extends React.Component {
    constructor(props) {
        super(props);
        this.paths = props
            .character
            .paths
            .map(({ startPath, endPath, style }) => {
                return {
                    interpolator: interpolate(startPath, endPath),
                    style
                };
            });
    }
    getPaths() {
        return this
            .paths
            .map(({ interpolator, style }) => {
                return {
                    d: interpolator(this.props.percent),
                    style
                };
            });
    }
    render() {
        const paths = this.getPaths();

        return (
            <svg className="Buddy" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
                {paths.map(function(path, index){
                    return <path key={ index } style={ path.style }
                                 d={ path.d }/>
                })}

            </svg>
        );
    }
}

export default Buddy;
